import gql from 'graphql-tag';

export const SpacesListSpaceFragment = gql`
	fragment SpacesListSpaceFragment on Space {
		alias
		id
		name
		key
		icon {
			path
		}
	}
`;
