import { AccessStatus, useSessionData } from '@confluence/session-data';

export interface IsExternalCollaboratorDataType {
	isExternalCollaborator: boolean;
}

/**
 * @deprecated This hook is deprecated and will be removed in future versions.
 * Please use `useIsGuest` from the `@confluence/session-data` package instead.
 *
 * @returns {IsExternalCollaboratorDataType} An object containing a boolean value indicating if the user is an external collaborator.
 */
export const useIsExternalCollaborator = (): IsExternalCollaboratorDataType => {
	const { accessStatus } = useSessionData();

	return {
		isExternalCollaborator: accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS,
	};
};
