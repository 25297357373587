import { useQuery } from 'react-apollo';
import type { GraphQLError } from 'graphql';
import type { ApolloError } from 'apollo-client';

import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { markErrorAsHandled } from '@confluence/graphql';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';

import {
	StarredSpacesQuery,
	MyVisitedSpacesQuery,
	MyVisitedSpacesWithCurrentSpaceQuery,
	AnonymousSpacesQuery,
} from './SpacesListQueries.graphql';
import type { SpacesListSpaceFragment as SpaceFragment } from './__types__/SpacesListSpaceFragment';
import type { StarredSpacesQuery as StarredResult } from './__types__/StarredSpacesQuery';
import type { AnonymousSpacesQuery as AnonymousResult } from './__types__/AnonymousSpacesQuery';
import type {
	MyVisitedSpacesWithCurrentSpaceQuery as VisitedWithCurrentResult,
	MyVisitedSpacesWithCurrentSpaceQueryVariables as VisitedWithCurrentVars,
} from './__types__/MyVisitedSpacesWithCurrentSpaceQuery';
import type { MyVisitedSpacesQuery as VisitedResult } from './__types__/MyVisitedSpacesQuery';

export type Space = SpaceFragment & {
	currentUser?: {
		isFavourited: boolean;
	};
};

const hasCurrentSpace = (
	data: VisitedResult | VisitedWithCurrentResult,
): data is VisitedWithCurrentResult => 'space' in data;

const isCurrentSpaceNotFoundError = (gqlError: GraphQLError) =>
	gqlError.path?.[0] === 'space' && gqlError.extensions?.['statusCode'] === 404;

const processStarredError = (error?: ApolloError): ApolloError | undefined => {
	if (!error) {
		return undefined;
	}

	if (isUnauthorizedError(error)) {
		markErrorAsHandled(error);
		return undefined;
	}

	return error;
};

const processRecentError = (error?: ApolloError): ApolloError | undefined => {
	if (!error) {
		return undefined;
	}

	/**
	 * space
	 *    403: Expected to occur when the user is on a restricted space
	 *    404: Expected to occur when the user is on an invalid space
	 * recentSpaces
	 *    403: Harmless error temporarily being ignored to mitigate CONFDEV-71458.
	 *         Removing in CONFDEV-71656.
	 */
	if (
		isUnauthorizedError(error) ||
		(!error.networkError && error.graphQLErrors.every(isCurrentSpaceNotFoundError))
	) {
		markErrorAsHandled(error);
		return undefined;
	}

	return error;
};

export const useStarredData = (isLicensed: boolean) => {
	const query = isLicensed ? StarredSpacesQuery : AnonymousSpacesQuery;

	const { data, loading, error } = useQuery<StarredResult | AnonymousResult>(query, {
		variables: {},
	});

	const starredSpaces: Space[] = (data?.spaces?.nodes || [])
		.filter((space): space is Space => space != null)
		.map((space) => ({
			...space,
			currentUser: {
				isFavourited: isLicensed,
			},
		}));
	const unexpectedError = processStarredError(error);

	return {
		starredSpaces,
		loading,
		error: unexpectedError,
	};
};

const useRecentData = (isLicensed: boolean) => {
	const [spaceKey] = usePageSpaceKey();

	const VisitedQuery = spaceKey ? MyVisitedSpacesWithCurrentSpaceQuery : MyVisitedSpacesQuery;

	const { data, loading, error } = useQuery<
		VisitedResult | VisitedWithCurrentResult,
		VisitedWithCurrentVars
	>(VisitedQuery, {
		skip: !isLicensed,
		variables: { selectedSpaceKey: spaceKey },
		errorPolicy: 'all',
	});

	const currentSpace: Space | undefined =
		(data && hasCurrentSpace(data) && data.space) || undefined;

	const recentData = (data as VisitedResult)?.myVisitedSpaces?.nodes?.spaces;

	const recentSpaces: Space[] = (recentData || []).filter(
		(space): space is Space => space != null && (!currentSpace || currentSpace.id !== space.id),
	);

	const unexpectedError = processRecentError(error);

	return {
		recentSpaces,
		currentSpace,
		loading,
		error: unexpectedError,
	};
};

export const useSpacesData = () => {
	const { isLicensed } = useSessionData();
	const recentSpacesLimit = fg('confluence_nav_4_beta') ? 5 : 15;

	const {
		starredSpaces,
		loading: starredLoading,
		error: starredError,
	} = useStarredData(isLicensed);

	const {
		recentSpaces: unfilteredRecents,
		currentSpace,
		loading: recentLoading,
		error: recentError,
	} = useRecentData(isLicensed);

	const starredIds = new Set(starredSpaces.map((starred) => starred.id));
	const recentSpaces = unfilteredRecents
		.filter((recent) => !starredIds.has(recent.id))
		.slice(0, recentSpacesLimit);

	const errors: ApolloError[] = [];
	if (starredError) errors.push(starredError);
	if (recentError) errors.push(recentError);

	return {
		errors,
		loading: starredLoading || recentLoading,
		starredSpaces,
		recentSpaces,
		currentSpace,
	};
};
