import { getTokenValue } from '@atlaskit/tokens';

import { reduceLightness, getLightnessPercentage } from '@confluence/color-utils';

type CustomThemeOptions = {
	backgroundColorRaw: string | null | undefined;
	highlightColorRaw: string | null | undefined;
	isDarkMode: boolean;
	isNav4Enabled: boolean;
};

const DEFAULT_HIGHLIGHT_COLORS = [
	'0052CC', // ADG3,
	'0C66E4', // ADG4
];

export const getCustomTheme = ({
	backgroundColorRaw,
	highlightColorRaw,
	isDarkMode,
	isNav4Enabled,
}: CustomThemeOptions) => {
	if (!backgroundColorRaw || !highlightColorRaw) {
		return;
	}

	const backgroundColor = formatColor(backgroundColorRaw);
	const highlightColor = formatColor(highlightColorRaw);

	// Background and highlight colors match the color values for default the default look-and-feel
	if (
		backgroundColor === formatColor(getTokenValue('elevation.surface', '#FFFFFF')) &&
		DEFAULT_HIGHLIGHT_COLORS.some(
			(color) => highlightColor === formatColor(getTokenValue('color.text.brand', `#${color}`)),
		)
	) {
		return;
	}

	if (
		!isValidColor(backgroundColor, isNav4Enabled) ||
		!isValidColor(highlightColor, isNav4Enabled)
	) {
		return;
	}

	return {
		backgroundColor: isDarkMode ? getDarkModeBackgroundColor(backgroundColor) : backgroundColor,
		highlightColor,
	};
};

const BACKGROUND_LIGHTNESS_MAX = 35;

const getDarkModeBackgroundColor = (currentBackgroundColor: string): string => {
	if (currentBackgroundColor.toLowerCase() === '#ffffff') {
		// getTokenValue doesn't work in SSR thus hardcode fallback to current surface value, fix in: https://product-fabric.atlassian.net/browse/DSP-9781
		return formatColor(getTokenValue('elevation.surface', '#1d2125'));
	} else {
		const lightnessPercentage = getLightnessPercentage(currentBackgroundColor);
		if (lightnessPercentage === undefined) {
			return currentBackgroundColor;
		}
		return lightnessPercentage > BACKGROUND_LIGHTNESS_MAX
			? reduceLightness(currentBackgroundColor, BACKGROUND_LIGHTNESS_MAX)
			: currentBackgroundColor;
	}
};

const formatColor = (color: string) => {
	if (color.startsWith('#')) {
		const formattedHex = color.trim().toUpperCase();
		if (formattedHex.length === 4) {
			const [c1, c2, c3] = formattedHex.substring(1, 4);
			return `#${c1}${c1}${c2}${c2}${c3}${c3}`;
		}
		return formattedHex;
	} else if (color.startsWith('rgba(') && color.split(',').length === 4) {
		return `rgb(${color.substring(5, color.lastIndexOf(','))})`;
	} else if (color.startsWith('var(')) {
		return formatColor(getCSSVariableValue(color)); // Will only get re-calculated if the variable is from ADS
	}
	return color;
};

const isValidColor = (color: string, isNav4Enabled: boolean) => {
	if (isNav4Enabled) {
		// Other color formats are not yet supported in Nav4, but may be later:
		// https://atlassian.slack.com/archives/C06PDP3LZ25/p1731554133364889?thread_ts=1730869773.044669&cid=C06PDP3LZ25
		return color.startsWith('#');
	}

	return (
		color.startsWith('#') ||
		color.startsWith('rgb(') ||
		color.startsWith('rgba(') ||
		color.startsWith('hsl(')
	);
};

const getCSSVariableValue = (variableExpression: string) => {
	const matcher = variableExpression.match(/var\(([^,\)]+)(,.*)?\)/);
	if (matcher) {
		const variable = matcher[1].trim();
		const fallback = matcher[2] ? matcher[2].replace(',', '').trim() : '';
		if (typeof document === 'undefined') return fallback;
		const value = window
			.getComputedStyle(document.documentElement)
			.getPropertyValue(variable)
			.trim();

		return value || fallback;
	}

	return '';
};
