// web items
export const TOOLS_MENU_CONTAINER = 'system.content.action';
export const PAGE_BUTTONS = 'system.content.button';
export const SYSTEM_DASHBOARD = 'system.dashboard';
export const SYSTEM_CONTENT_BYLINE = 'system.content.byline';
export const SYSTEM_CONTENT_METADATA = 'system.content.metadata';
export const PAGE_METADATA_BANNER = 'page.metadata.banner';
export const SYSTEM_HEADER_LEFT = 'system.header/left';
export const SYSTEM_HEADER_LEFT_BUTTON_GROUP = 'system.header/left.button.group';
export const SYSTEM_HELP = 'system.help/pages';
export const SYSTEM_USER = 'system.user';
export const SYSTEM_ADMIN = 'system.admin';
export const SYSTEM_EDITOR_PRECURSOR_BUTTONS = 'system.editor.precursor.buttons';
export const CREATE_DIALOG = 'system.create.dialog/content';
export const CREATE_SPACE_DIALOG = 'system.create.space.dialog/content';
export const ACTION_PANEL = 'page.view.selection/action-panel';

// web panels
export const DASHBOARD_SECONDARY = 'atl.dashboard.secondary';
export const ATL_GENERAL = 'atl.general';
export const ATL_FOOTER = 'atl.footer';
export const ATL_PAGE_METADATA = 'atl.page.metadata.banner';
export const ATL_PAGE_CONTENT_FOOTER_ACTIONS = 'atl.page.content.footer.actions';
export const ATL_EDITOR_SAVEBAR = 'atl.editor.savebar';

// web sections
export const SYSTEM_ADMIN_SITE_ADMINISTRATION = 'system.admin/siteadministration';
export const SYSTEM_ADMIN_SITE_ADMINISTRATION_SECURITY = 'system.admin/security';
