import { useThemeObserver } from '@atlaskit/tokens';

import { getSavedThemeLocal } from '@confluence/theming/entry-points/getSavedThemeLocal';

export const useIsDarkMode = () => {
	// Using hook and getTokenValue() vs token() because of equality comparison used in generateValidTheme()
	//    The hook allows these variables to re-compute when the theme is changed.
	//    Even when the look-and-feel defaults are tokenized, if the site has configured and reverted back to the original
	//      colors, if they aren't using the css var w/ tokens, the value here would not match if just using token()
	const adsTheme = useThemeObserver();

	// Use locally stored theme for SSR because `useThemeObserver()` won't work in SSR
	//  also, "auto" won't work in SSR if resolves to "dark" because we cannot get the system preference off the window
	//  see https://product-fabric.atlassian.net/browse/DSP-9781
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const effectiveTheme = process.env.REACT_SSR ? getSavedThemeLocal() : adsTheme;

	return effectiveTheme?.colorMode === 'dark';
};
