import React from 'react';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

const EditionAwarenessLoadable = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-EditionAwareness" */ './EditionAwareness'))
			.EditionAwareness,
});

export const EditionAwareness = withTransparentErrorBoundary({
	attribution: Attribution.GROWTH_LUNA,
})(() => {
	return <EditionAwarenessLoadable />;
});
